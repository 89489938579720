<!-- eslint-disable max-len -->
<template>
  <div class="intro-view__aperol-spritz">
    <svg width="780" height="298" viewBox="0 0 780 298" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.6" d="M760.5 21.4376L760.5 206.262L19.5 276.562L19.5 91.7376L760.5 21.4376Z" stroke="url(#paint0_linear_384_2)" stroke-width="39"/>
<path d="M92.5748 93.6851C93.36 99.705 98.4202 111.047 114.648 111.047C126.251 110.96 136.284 104.94 136.284 92.7254C136.284 82.7796 131.05 77.3704 120.406 74.0551L113.339 71.874C108.017 70.3036 105.138 68.297 105.138 63.673C105.138 59.3108 108.541 55.9083 115.258 55.9083C123.372 55.9083 125.728 61.5791 126.251 65.2434L135.935 63.324C135.063 56.9552 130.439 46.4858 115.171 46.4858C103.219 46.4858 95.1922 54.1634 95.1922 64.5455C95.1922 73.3572 99.9906 78.4173 109.239 81.2092L116.305 83.303C123.808 85.5714 126.338 88.5377 126.338 93.0744C126.338 98.3091 122.761 101.624 115.171 101.624C105.4 101.624 102.957 94.8193 102.521 91.6785L92.5748 93.6851Z" fill="white"/>
<path d="M182.011 72.9209L201.902 47.7945H189.688L165.87 78.9408V47.7945H156.012V109.738H165.87V93.0744L175.467 80.9474L191.346 109.738H202.775L182.011 72.9209Z" fill="white"/>
<path d="M237.683 71.6995L223.113 47.7945H212.208L232.623 80.9474V109.738H242.569V80.9474L262.984 47.7945H252.166L237.683 71.6995Z" fill="white"/>
<path d="M298.764 71.6995L284.195 47.7945H273.289L293.704 80.9474V109.738H303.65V80.9474L324.065 47.7945H313.247L298.764 71.6995Z" fill="white"/>
<path d="M326.27 100.791C321.64 100.791 317.949 104.576 317.949 109.3C317.949 114.055 321.64 117.809 326.27 117.809C330.9 117.809 334.591 114.055 334.591 109.3C334.591 104.576 330.9 100.791 326.27 100.791ZM326.27 115.713C322.798 115.713 320.014 112.866 320.014 109.3C320.014 105.734 322.798 102.887 326.27 102.887C329.774 102.887 332.527 105.734 332.527 109.3C332.527 112.866 329.774 115.713 326.27 115.713ZM329.836 107.861C329.836 106.14 328.616 105.014 326.739 105.014H323.236V113.68H325.269V110.739H326.427L327.772 113.68H329.962L328.304 110.332C328.835 110.019 329.836 109.3 329.836 107.861ZM325.269 108.862V106.922H326.552C327.396 106.922 327.678 107.298 327.678 107.861C327.678 108.424 327.365 108.862 326.552 108.862H325.269Z" fill="white"/>
<path d="M410.333 95.1683L396.112 47.7945H385.643L405.099 109.738H415.219L434.675 47.7945H424.467L410.333 95.1683Z" fill="white"/>
<path d="M471.24 46.4858C458.59 46.4858 449.255 54.8613 449.255 69.6056V87.927C449.255 102.671 458.59 111.047 471.24 111.047C483.891 111.047 493.226 102.671 493.226 87.927V69.6056C493.226 54.8613 483.891 46.4858 471.24 46.4858ZM483.193 88.7122C483.193 97.5239 477.958 101.712 471.24 101.712C464.435 101.712 459.288 97.5239 459.288 88.7122V68.8204C459.288 60.0087 464.435 55.821 471.24 55.821C477.958 55.821 483.193 60.0087 483.193 68.8204V88.7122Z" fill="white"/>
<path d="M512.517 47.7945V109.738H532.322C545.059 109.738 554.394 101.363 554.394 86.7056V70.8271C554.394 56.17 545.059 47.7945 532.322 47.7945H512.517ZM544.449 87.4035C544.449 96.2152 539.127 100.403 532.322 100.403H522.376V57.1297H532.322C539.127 57.1297 544.449 61.3174 544.449 70.1291V87.4035Z" fill="white"/>
<path d="M598.899 72.9209L618.791 47.7945H606.577L582.759 78.9408V47.7945H572.901V109.738H582.759V93.0744L592.356 80.9474L608.235 109.738H619.664L598.899 72.9209Z" fill="white"/>
<path d="M679 109.738L659.545 47.7945H649.424L629.969 109.738H640.176L644.277 95.6045H664.43L668.531 109.738H679ZM654.397 61.2302L661.726 86.5311H646.981L654.397 61.2302Z" fill="white"/>
<path d="M143.735 251H145.735V249V164.411H178.055H180.055V162.411V147.046V145.046H178.055H93.0447H91.0447V147.046V162.411V164.411H93.0447H125.365V249V251H127.365H143.735ZM274.282 251H277.381L276.105 248.176L256.107 203.936C261.814 202.195 266.605 199.182 270.079 194.86C274.021 189.958 276.139 183.533 276.139 175.765V175.191C276.139 165.375 272.796 157.741 266.767 152.596C260.786 147.492 252.407 145.046 242.691 145.046H202.196H200.196V147.046V249V251H202.196H218.423H220.423V249V205.767H235.791L255.367 249.812L255.894 251H257.194H274.282ZM368.5 251H370.5V249V233.635V231.635H368.5H346.806V164.411H368.5H370.5V162.411V147.046V145.046H368.5H304.742H302.742V147.046V162.411V164.411H304.742H326.436V231.635H304.742H302.742V233.635V249V251H304.742H368.5ZM437.438 218.043L415.953 146.471L415.525 145.046H414.037H396.805H394.081L394.897 147.645L426.92 249.6L427.359 251H428.828H445.485H446.953L447.393 249.6L479.415 147.645L480.232 145.046H477.507H460.706H459.216L458.79 146.474L437.438 218.043ZM569.571 251H571.571V249V233.635V231.635H569.571H547.877V164.411H569.571H571.571V162.411V147.046V145.046H569.571H505.813H503.813V147.046V162.411V164.411H505.813H527.507V231.635H505.813H503.813V233.635V249V251H505.813H569.571ZM678.578 251H681.303L680.487 248.401L648.464 146.446L648.024 145.046H646.556H629.899H628.431L627.991 146.446L595.968 248.401L595.152 251H597.876H614.677H616.18L616.598 249.557L622.929 227.737H653.095L659.426 249.557L659.844 251H661.347H678.578ZM242.404 164.411C246.812 164.411 250.107 165.399 252.262 167.129C254.347 168.803 255.625 171.377 255.625 175.191V175.765C255.625 179.496 254.355 182.035 252.269 183.696C250.111 185.414 246.811 186.402 242.404 186.402H220.423V164.411H242.404ZM628.548 208.803L638.072 176.309L647.484 208.803H628.548Z" fill="white" stroke="white" stroke-width="4"/>
<defs>
<linearGradient id="paint0_linear_384_2" x1="146.617" y1="276" x2="758.173" y2="43.4302" gradientUnits="userSpaceOnUse">
<stop stop-color="#00BDD3"/>
<stop offset="1" stop-color="#08C4DA"/>
</linearGradient>
</defs>
</svg>

  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.intro-view__aperol-spritz {
}
</style>
