<template>
  <div class="form-view-content bg-content animation">
    <div class="form">
      <div v-if="this.redirectUrl === '/trivia'" class="skip">
        <el-button @click="skip()">
          <div>
            <el-icon>
              <CaretRight/>
            </el-icon>
            <el-icon class="icon-2">
              <CaretRight/>
            </el-icon>
          </div>
          skip
        </el-button>
      </div>
      <div class="head">
        <img src="../../public/img/images/logo_site.svg" alt="">
        <h2>
          JOIN THE SKYY<sub>®</sub> VODKA COMMUNITY!
        </h2>
        <p>
          Don’t miss our exclusive email offers, including future events, promotions and news.
        </p>
      </div>
      <div class="body">
        <form-component
          :redirectUrl="redirectUrl"
        ></form-component>
      </div>
      <div class="footer">
        <p>
          By submitting this form, you agree to our <a target="_blank" href="https://www.skyyvodka.com/en-us/privacy-policy">Privacy Policy</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import FormComponent from '@/components/FormComponent.vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CaretRight } from '@element-plus/icons-vue';
import IndexedDB from '../../utils/indexedDB';

export default {
  name: 'FormView',
  components: { FormComponent, CaretRight },
  data() {
    return {
      questionsToShow: null,
    };
  },
  computed: {
    ...mapState(['uuid', 'startTime']),
    redirectUrl() {
      if (this.questionsToShow === '0' || this.questionsToShow === 0) {
        return '/winner';
      }
      return '/trivia';
    },
  },

  methods: {
    ...mapMutations(['setStartTime']),
    skip() {
      // IndexedDB.addAnimationTop();
      this.$store.commit('setStartTime', new Date());
      setTimeout(() => {
        this.$router.push('/trivia');
      }, 500);
    },
    async endTrivia() {
      // Process database
      const indexed = process.env.VUE_APP_INDEXED_DB;

      const endTime = new Date();
      // in ms
      let timeDiff = endTime - this.startTime;
      // strip the ms
      timeDiff /= 1000;

      // get seconds
      const timeElapsed = Math.round(timeDiff);

      await IndexedDB.updateEntries({
        name: indexed,
        user: this.uuid,
        data: {
          completionReason: 'finished',
          qrShown: 1,
          timeElapsed,
          retries: 0,
        },
      });
      this.$store.commit('setUUID', null);
      this.$store.commit('setStartTime', null);
      setTimeout(() => {
        this.$router.push('/winner');
      }, 1000);
    },
  },
  async mounted() {
    const indexed = process.env.VUE_APP_INDEXED_DB;
    const AllData = await IndexedDB.getIndexedDB(indexed);
    const { completionReason } = AllData.filter((word) => word.uuid === this.uuid)[0] || {};

    if (completionReason === '') {
      const {
        questions_to_show: questionsToShow,
      } = await IndexedDB.getIndexedDB('SETTINGS') || {};

      this.questionsToShow = questionsToShow;
    }
  },
};
</script>
<style lang="scss">
.form-view-content {
  .form {
    .skip {
      .el-button {
        width: 98px;
        height: 42px;
        background: #FFFFFF;
        border-radius: 58px;
        font-family: "SKYY MONO BOLD", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        text-align: right;
        text-transform: uppercase;
        color: #0042B9;
        position: absolute;
        right: 40px;
        top: 20px;

        & > span {
          gap: 10px;
        }

        .icon-2 {
          margin-left: -23px;
        }

        &:hover {
          background: rgba(255, 242, 237, 0.76);
        }
      }
    }

    .body {
      margin: auto;

      .el-checkbox {
        .el-checkbox__inner {
          background: rgba(255, 245, 241, 0.65);
          border: 1.63125px solid #FA4801;
          width: 25px;
          height: 24px;

          &::after {
            height: 12px;
            left: 10px;
            top: 3px;
          }
        }
      }

    }

    .footer {
      bottom: 20px;
      a {
        color: white;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
