<template>
  <div class="intro-view bg-content">
    <div class="logo-container">
      <!-- <logo-component class="logo" /> -->
      <logo-component class="logo"/>
    </div>
    <!-- <div class="aperol-logo-container animate shrink-aperol"> -->
    <div class="aperol-logo-container">
      <aperol-spritz class="aperol-spritz"/>
      <!-- <trivia-game class="trivia-game"/> -->
    </div>
    <!-- <div class="main-contentdcda hide animate__animated animate__fadeInUpd"> -->
    <div class="main-content hide">
      <h2>Show us your knowledge of SKYY and San Francisco.</h2>
      <h1>
        Play our visual trivia game to get a
        <br>
        special offer from us.
      </h1>
      <button @click="handleStartButton">LET'S START</button>
    </div>
    <!-- <div class="footer-container animate"> -->
    <div class="footer-container">
      <p>
        SKYY® Vodka distilled from grain. 40% alc./vol. (80 Proof).
        <br>
        ©2023 Campari America, New York, NY. Please enjoy responsibly.
      </p>
    </div>
  </div>
</template>

<script>
// import { toRaw } from 'vue';
// import animo from 'animo-core';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'animate.css';
import LogoComponent from '@/components/IntroView/LogoComponent.vue';
import AperolSpritz from '@/components/IntroView/AperolSpritz.vue';
// import TriviaGame from '@/components/IntroView/TriviaGame.vue';
import IndexedDB from '../../utils/indexedDB';

export default {
  components: {
    LogoComponent,
    AperolSpritz,
    // TriviaGame,
  },
  data() {
    return {
      animate: true,
      timeoutId: null,
    };
  },
  methods: {
    handleStartButton() {
      this.$router.push({ path: '/age-gate' });
    },
    async sleep(duration = 1000) {
      return new Promise((resolve) => {
        setTimeout(() => resolve(true), duration);
      });
    },
    async waitAnimation(target, newClass = null) {
      return new Promise((resolve) => {
        function onAnimationEnd() {
          // Remove the event listener
          // console.log('animation complete - ', newClass);
          target.removeEventListener('animationend', onAnimationEnd);
          // Call the callback function
          resolve(target);
        }

        // Add a listener for the animationend event
        target.addEventListener('animationend', onAnimationEnd);
        if (newClass != null) {
          target.classList.add(newClass);
        }
      });
    },
    async runAnimation() {
      const { animate, sleep, waitAnimation } = this;
      if (!animate) {
        return;
      }

      // Elements
      const logo = document.querySelector('.intro-view .logo');
      const logoContainer = document.querySelector('.intro-view .aperol-logo-container');
      const footerContainer = document.querySelector('.intro-view .footer-container');
      const mainContent = document.querySelector('.intro-view .main-content');

      // console.log('Starting animation cycle....');

      await Promise.all([
        waitAnimation(logo, 'shrink'),
        waitAnimation(logoContainer, 'animate'),
        waitAnimation(footerContainer, 'animate'),
      ]);
      logoContainer.classList.remove('shrink-aperol-reverse');
      await waitAnimation(logoContainer, 'shrink-aperol');

      // Show main content
      mainContent.classList.add('animate__animated');
      await waitAnimation(mainContent, 'animate__fadeInUp');

      await sleep(5000);

      // Step 2 - Enter Aperol Spritz

      // Reverse Logo
      // if (logo) {
      //   this.animate = false;
      //   return;
      // }
      mainContent.classList.remove('animate__fadeInUp');
      mainContent.classList.add('animate__fast');
      await waitAnimation(mainContent, 'animate__fadeOutDown');
      mainContent.classList.remove('animate__fast');
      logoContainer.classList.remove('shrink-aperol');
      await waitAnimation(logoContainer, 'shrink-aperol-reverse');

      logoContainer.classList.remove('animate');
      logo.classList.remove('shrink');
      footerContainer.classList.remove('animate');
      footerContainer.classList.add('animate__animated');

      await Promise.all([
        waitAnimation(logoContainer, 'animate-reverse'),
        waitAnimation(logo, 'shrink-reverse'),
        waitAnimation(footerContainer, 'animate__fadeOutDown'),
      ]);
      footerContainer.classList.remove('animate', 'animate__animated', 'animate__fadeOutDown');

      // Reset reverse classes
      logo.classList.remove('shrink-reverse', 'shrink');
      mainContent.classList
        .remove('animate__animated', 'animate__fadeOutDown', 'animate__fadeInUp');
      logoContainer.classList.remove('animate', 'animate-reverse', 'shrink-aperol');
      await sleep();
    },
  },
  async mounted() {
    // document.getElementById('loader').style.display = 'flex';

    // this.$router.push('/winner');

    this.timeoutId = setTimeout(async () => {
      const {
        show_intro: showIntro,
      } = await IndexedDB.getIndexedDB('SETTINGS') || {};

      // console.log('🚀 --------------------------------------------------------🚀');
      // console.log('🚀 ~ IntroView.vue:144 ~ this.timeoutId=setTimeout ~ showIntro:', showIntro);
      // console.log('🚀 ------------------------------------------------------🚀');
      // document.getElementById('loader').style.display = 'none';
      if (showIntro === '0') {
        this.$router.push({ name: 'AgeView' });
      }
    }, 2000);

    const { runAnimation, sleep } = this;
    this.animate = true;

    while (this.animate) {
      // console.log('new iteration....');
      try {
        // eslint-disable-next-line no-await-in-loop
        await runAnimation();
        // break;
      } catch (e) {
        console.error(e.message);
        console.error('EXPLODED');
      }
      // eslint-disable-next-line no-await-in-loop
      await sleep(2000);
    }
  },
  beforeUnmount() {
    // console.log('Stopping animation...');
    this.animate = false;
    clearTimeout(this.timeoutId);
    // document.getElementById('loader').style.display = 'none';
  },
};

</script>

<style lang="scss" scoped>
.intro-view {
  overflow: hidden;
  background-color: #0A1349;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .hide {
    opacity: 0;
  }

  .logo-container {
    .logo {
      position: relative;

      &.shrink {
        animation: shrink 1s forwards;

        &.shrink-reverse {
          // opacity: 0.5;
          animation: shrink-reverse 1s forwards;
          // animation-play-state: running;
        }
      }

      &.shrink-reverse {
        animation: shrink-reverse 1s forwards;
      }
    }
  }

  .aperol-logo-container {
    position: fixed;
    transform: translateX(-4%);

    .aperol-spritz {
      position: relative;
      // Final position
      transform: translate(-2000%, 20%);
    }

    .trivia-game {
      position: relative;
      // transform: translate(13%, -43%);
      transform: translate(2000%, -43%);
    }

    &.animate {
      top:185px;
      .aperol-spritz {
        animation: enter-left 2.5s forwards;
      }

      .trivia-game {
        animation: enter-right 2.5s forwards;
        // transform: translate(13%, -43%);
      }
    }

    &.animate-reverse {
      .aperol-spritz {
        animation: exit-left 1.5s forwards;
      }

      .trivia-game {
        animation: exit-right 1.5s forwards;
      }
    }

    &.shrink-aperol {
      animation: shrink-aperol 1s forwards;
      // scale: 0.64;
      // top: calc(100vh - 88%);
      // top: 385px;
    }

    &.shrink-aperol-reverse {
      animation: shrink-aperol-reverse 1s forwards;
    }
  }

  .footer-container {
    position: fixed;
    // bottom: 52px;
    bottom: -1000px;

    font-family: 'Maison Neue Book';
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    &.animate {
      animation: enter-footer 2.5s forwards;
    }
  }

  .main-content {
    position: fixed;
    bottom: 155px;

    h1 {
      font-family: 'Maison Neue Bold';
      color: white;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      // text-transform: uppercase;
    }

    h2 {
      font-family: 'Maison Neue Book';
      color: white;
      font-size: 24px;
      line-height: 29px;
      // text-transform: uppercase;
    }
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 214px;
    height: 49px;
    background: #0A1349;
    box-shadow: 0px 3.77673px 7.55347px #0A1349;
    border-radius: 51px;
    color: #FFFFFF;
    font-size: 22px;
    font-family: 'SKYY MONO BOLD';
    min-width: 404px;

    &:hover {
      color: #0A1349;
      background: rgba(255, 242, 237, 0.76);
    }
  }

  @keyframes shrink {
    0% {
      scale: 1;
      top: 0;
    }
    100% {
      scale: 0.3;
      top: -285px;
    }
  }
  @keyframes shrink-reverse {
    0% {
      scale: 0.3;
      top: -285px;
    }
    100% {
      scale: 1;
      top: 0;
    }
  }
  @keyframes enter-left {
    0% {
      transform: translate(-2000%, 20%);
    }
    100% {
      transform: translate(0%, 20%);
    }
  }
  @keyframes exit-left {
    0% {
      transform: translate(0%, 20%);
    }
    100% {
      transform: translate(-2000%, 20%);
    }
  }
  @keyframes enter-right {
    0% {
      transform: translate(2000%, -43%);
    }
    100% {
      transform: translate(21%, -47%);
    }
  }
  @keyframes exit-right {
    0% {
      transform: translate(21%, -47%);
    }
    100% {
      transform: translate(2000%, -43%);
    }
  }

  @keyframes shrink-aperol {
    0% {
      scale: 1;
      top: 185px;
    }
    100% {
      scale: 0.64;
      top: calc(100vh - 80%);
      // top: 85px !important;
    }
  }

  @keyframes shrink-aperol-reverse {
    0% {
      scale: 0.64;
      top: calc(100vh - 80%);
      // top: 385px;
    }
    100% {
      scale: 1;
      top: 185px;
    }
  }

  // @media only screen and (max-width: 2000px) and (orientation: landscape) {
  //   .aperol-logo-container {
  //     top: 85px;
  //   }
  // }

  @media only screen and (min-height: 700px) and (max-height: 900px) and (orientation: landscape) {
    .aperol-logo-container {
      top: 85px !important;
    }
    @keyframes shrink-aperol {
      0% {
        scale: 1;
        top: 85px;
      }
      100% {
        scale: 0.64;
        // top: -25px;
        top: 90px;
      }
    }
  }

  @media only screen and (min-height: 700px) and (max-height: 900px) and (orientation: landscape) {
    @keyframes shrink-aperol-reverse {
      0% {
        scale: 0.64;
        // top: -25px;
        top: 90px;
      }
      100% {
        scale: 1;
        top: 85px;
      }
    }
  }

  @keyframes enter-footer {
    0% {
      bottom: -1000px;
    }
    100% {
      bottom: 52px;
    }
  }
  @keyframes exit-footer {
    0% {
      bottom: 52px;
    }
    100% {
      bottom: -1000px;
    }
  }

}
</style>
